import Layout from '@/layout/index.vue';
import type { RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';


const routes: Array<RouteRecordRaw> = [

  {
    path: '/',
    name: 'root',
    component: Layout,
    redirect: 'home',
    meta: {
      title: '天海灵境：提供基于大模型的行业定制服务与3D数字化解决方案',
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomeView,
        meta: {
          title: '天海灵境：提供基于大模型的行业定制服务与3D数字化解决方案',
        },
      }
    ],
  },
];

export default routes;
