import { useCachedViewStoreHook } from '@/stores/cachedView';
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
const cachedViews = computed(() => useCachedViewStoreHook().cachedViewList);
var userAgent = navigator.userAgent;
var isWeixin = userAgent.toLowerCase().indexOf('micromessenger') !== -1; // 微信内
var isMac = /(mac|Mac|macOS|OSX|MacOS)/i.test(userAgent); //Mac终端
var isWindows = /(win|Win|windows|Window|Windows)/i.test(userAgent); //windows终端
export default defineComponent({
  name: 'HomeView',
  components: {},
  computed: {},
  setup() {
    let iStabletDevice = ref(false);
    let mySwipeHeight = ref(500);
    let linkcardPreviewHeight = ref(470);
    const rightContentViewBg = ref('');
    const showLinkardQrCode = ref(false);
    const showDownLoadView = ref(false);
    const updateScreenClass = () => {
      const screenWidth = window.screen.width;
      if (screenWidth <= 1440) {
        console.log("打印测试01", screenWidth);
        rightContentViewBg.value = 'right_content_view_bg_small';
        mySwipeHeight.value = 350;
        linkcardPreviewHeight.value = 350;
      } else if (screenWidth > 1440 && screenWidth <= 1650) {
        rightContentViewBg.value = 'right_content_view_bg_medium';
        console.log("打印测试02", screenWidth);
        mySwipeHeight.value = 430;
        linkcardPreviewHeight.value = 430;
      } else if (screenWidth > 1650 && screenWidth <= 1720) {
        rightContentViewBg.value = 'right_content_view_bg_medium';
        console.log("打印测试03", screenWidth);
        mySwipeHeight.value = 450;
        linkcardPreviewHeight.value = 450;
      } else if (screenWidth > 1720 && screenWidth <= 1920) {
        rightContentViewBg.value = 'right_content_view_bg_medium';
        console.log("打印测试04", screenWidth);
        mySwipeHeight.value = 490;
        linkcardPreviewHeight.value = 490;
      } else if (screenWidth > 1920 && screenWidth <= 2200) {
        rightContentViewBg.value = 'right_content_view_bg_large';
        console.log("打印测试06", screenWidth);
        mySwipeHeight.value = 516;
        linkcardPreviewHeight.value = 516;
      } else if (screenWidth > 2200) {
        rightContentViewBg.value = 'right_content_view_bg_large';
        console.log("打印测试05", screenWidth);
        mySwipeHeight.value = 546;
        linkcardPreviewHeight.value = 546;
      }
      // const elements = document.getElementsByClassName("company_introduce_profile");
      // (elements[0] as HTMLElement).style.height = mySwipeHeight.value + "px"; // 设置为200px或你想要的其他高度
      // (elements[0] as HTMLElement).style.width = mySwipeHeight.value *1.777 + "px"; // 设置为200px或你想要的其他高度
      // const linkcardPreview = document.getElementsByClassName("linkcard_preview_content");
      // (linkcardPreview[0] as HTMLElement).style.height = linkcardPreviewHeight.value + "px"; // 设置为200px或你想要的其他高度
      // (linkcardPreview[0] as HTMLElement).style.width = linkcardPreviewHeight.value *0.4618 + "px"; // 设置为200px或你想要的其他高度
    };
    // 使用computed来生成带有响应式更新的screenClass
    const rightContentViewBgComputed = computed(() => rightContentViewBg.value);
    // 监听窗口大小变化来更新screenClass
    const handleResize = () => {
      updateScreenClass();
    };
    onMounted(() => {
      // 初始化
      updateScreenClass();
      window.addEventListener('resize', handleResize);
    });
    onBeforeUnmount(() => {
      // 在组件销毁前移除事件监听器
      window.removeEventListener('resize', handleResize);
    });
    const weiboClick = () => {
      window.location.href = "https://m.weibo.cn/u/7825848792?jumpfrom=weibocom";
    };
    const wechatClick = () => {
      window.location.href = "https://mp.weixin.qq.com/s/_oV2nt4rHU6FnD6V7jCVmg";
    };
    const downloadButtonClick = () => {
      showDownLoadView.value = true;
    };
    const gotoTianHaiLinkard = () => {
      showLinkardQrCode.value = true;
    };
    const showLinkardQrCodeMethod = () => {
      showLinkardQrCode.value = !showLinkardQrCode;
    };
    const showDownLoadViewMethod = () => {
      showDownLoadView.value = !showDownLoadView;
    };
    const windowsDownLoadClick = () => {
      window.location.href = 'https://resource.dese.cn/app/release/win/SkyseaLive_latest_win_release.exe';
    };
    const macDownLoadClick = () => {
      window.location.href = 'https://resource.dese.cn/app/release/osx/SkyseaLive_latest_osx_release.pkg';
    };
    return {
      cachedViews,
      companyinfosList: [require('../assets/images/company_instruction_1.png'), require('../assets/images/company_instruction_2.png'), require('../assets/images/company_instruction_3.png'), require('../assets/images/company_instruction_4.png')],
      logoImage: require('../assets/images/skysea_logo.svg'),
      weiboImage: require('../assets/images/contact_weibo.svg'),
      weichatImage: require('../assets/images/contact_wechat.svg'),
      phoneNumberIcon: require('../assets/images/phone_number_icon.svg'),
      linkcardPreview: require('../assets/images/linkard_preview.jpg'),
      linkcardBgLine: require('../assets/images/linkard_bg_line.svg'),
      downloadImg: require('../assets/images/down_load_icon.svg'),
      gotoTianHaiIcon: require('../assets/images/goto_tianhai_linkard_icon.svg'),
      linkardQrcode: require('../assets/images/linkard_qr_code.svg'),
      closeVanOverlay: require('../assets/images/close_van_overlay.svg'),
      rightContentViewBg: rightContentViewBgComputed,
      iStabletDevice,
      mySwipeHeight,
      showLinkardQrCode,
      showDownLoadView,
      weiboClick,
      wechatClick,
      gotoTianHaiLinkard,
      downloadButtonClick,
      showLinkardQrCodeMethod,
      showDownLoadViewMethod,
      windowsDownLoadClick,
      macDownLoadClick
    };
  }
});